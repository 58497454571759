import { yupResolver, } from '@hookform/resolvers/yup';
import {
  CustomerVerificationMethod,
  PreviouslyOwnedDevices,
  PurchasedProductsForGlobalDb,
  RegistrationType,
  useGetVerificationMethodsQuery,
  useStartCustomerVerificationMutation,
} from 'Apollo/graphql';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import LoadingBox from 'Components/LoadingBox';
import {
  ChooseVerificationMethodForm as ChooseVerificationMethodFormType,
  RegistrationStore,
  useRegistrationStore,
} from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useOptionsPhonePrefix, } from 'Utils/options/useOptionsPhonePrefix';
import { FormProvider, useForm, } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useEffect, useMemo, } from 'react';
import ChooseVerificationMethodForm from './ChooseVerificationMethodForm';
import { formValidationSchema, } from './utils';
import { findDeviceCodeInLists, } from '../PhaseInformationVerification/utils';

const selectStoreData = (s: RegistrationStore) => ({
  completeChooseVerificationMethodPhase: s.completeChooseVerificationMethodPhase,
  customer: s.customer,
  deviceCodeRequiredList: s.deviceCodeRequiredList,
  deviceCodeRequiredPromotionsList: s.deviceCodeRequiredPromotionsList,
  goBackToPhase: s.goBackToPhase,
  isShortRegistration: s.isShortRegistration,
});

const PhaseChooseVerificationMethod = (): JSX.Element => {
  const {
    completeChooseVerificationMethodPhase,
    customer,
    goBackToPhase,
    deviceCodeRequiredList,
    deviceCodeRequiredPromotionsList,
    isShortRegistration,
  } = useRegistrationStore(selectStoreData, shallow);

  const goBackToPurchase = () => goBackToPhase('purchase');
  const phonePrefixOptions = useOptionsPhonePrefix();
  const phonePrefixDefault = useMemo(
    () => phonePrefixOptions.options.find((option) => option.id === customer?.phonePrefix) || null,
    [phonePrefixOptions.options, customer?.phonePrefix,],
  );
  const phoneNumber = useMemo(() => customer?.phoneNumber || '', [customer?.phoneNumber,]);
  const methods = useForm<ChooseVerificationMethodFormType>({
    defaultValues: {
      phone: phoneNumber,
      phonePrefix: phonePrefixDefault,
      verificationMethod: null,
    },
    resolver: yupResolver(formValidationSchema()),
    mode: 'onSubmit',
  });

  const [startVerificationMutation, startVerificationResult,] = useStartCustomerVerificationMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      const { phone, verificationMethod, phonePrefix, } = methods.getValues();
      if (isShortRegistration) {
        completeChooseVerificationMethodPhase(null as unknown as CustomerVerificationMethod, '', {
          __typename: 'CustomerVerificationStartResultSkipVerification',
          skipVerification: true,
        });
        return;
      }

      completeChooseVerificationMethodPhase(
        verificationMethod?.id as CustomerVerificationMethod,
        verificationMethod?.id === CustomerVerificationMethod.Sms ? `${phonePrefix?.id}${phone}` : (customer?.email as string),
        result.startCustomerVerification,
      );
    },
    onError: () => {
      if (isShortRegistration) {
        completeChooseVerificationMethodPhase(null as unknown as CustomerVerificationMethod, '', {
          __typename: 'CustomerVerificationStartResultSkipVerification',
          skipVerification: true,
        });
      }
    },
  });

  const { loading, data, error, } = useGetVerificationMethodsQuery();
  const customerInput = {
    acceptedPulzeCare: customer?.acceptedPulzeCare || false,
    email: customer?.email || '',
    firstName: customer?.firstName || '',
    lastName: customer?.lastName || '',
    acceptedPulzeOn: customer?.acceptedPulzeOn || false,
    acceptedTermsAndConditions: customer?.acceptedTermsAndConditions || false,
    dateOfBirth: customer?.dateOfBirth || '',
    phoneNumber: customer?.phoneNumber || '',
    phonePrefix: customer?.phonePrefix || '',
    previouslyOwnedDevices: customer?.previouslyOwnedDevices,
    purchasedProductsForGlobalDB: customer?.purchasedProductsForGlobalDB,
    // FIXME: this should not be there, should be handled by the backend
    registrationType: RegistrationType.Standard,
  };

  const handleSubmit = methods.handleSubmit((values) => {
    const firstDeviceCodeFound = findDeviceCodeInLists(deviceCodeRequiredList, deviceCodeRequiredPromotionsList);
    return startVerificationMutation({
      variables: {
        verificatedValue:
          values.verificationMethod?.id === CustomerVerificationMethod.Sms ? `${values.phonePrefix?.id}${values.phone}` : (customer?.email as string),
        method: values.verificationMethod?.id as CustomerVerificationMethod,
        customerInput,
        deviceCode: firstDeviceCodeFound,
      },
    });
  });

  useEffect(() => {
    const firstDeviceCodeFound = findDeviceCodeInLists(deviceCodeRequiredList, deviceCodeRequiredPromotionsList);
    if (
      data?.getVerificationMethods.verificationMethods.length === 0 ||
      (typeof data?.getVerificationMethods.verificationMethods.length === 'number' && isShortRegistration)
    ) {
      startVerificationMutation({
        variables: {
          verificatedValue: customerInput.email,
          method: null,
          customerInput,
          deviceCode: firstDeviceCodeFound,
        },
      });
    }
  }, [data,]);

  if (loading || startVerificationResult.loading) {
    return <LoadingBox />;
  }
  if (error || !data) {
    <ErrorAlert forceError error={error} />;
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate>
        <ChooseVerificationMethodForm
          goBack={goBackToPurchase}
          verificationMethods={data?.getVerificationMethods.verificationMethods || []}
          email={customer?.email || ''}
        />
      </form>
    </FormProvider>
  );
};
export default PhaseChooseVerificationMethod;
