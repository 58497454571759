import {
  AssortmentOptionFragment,
  CustomerInput,
  CustomerVerificationMethod,
  CustomerVerificationStartResult,
  PointOfSellOptionFragment,
  PreviouslyOwnedDevices,
  PreviouslyOwnedDevicesOptionFragment,
  PromotionAssortmentCategoryFragment,
  PromotionAvailabilityFragment,
  PromotionWithLimitOptionFragment,
  PurchasedProductsForGlobalDb,
  PurchasedProductsForGqlDbOptionFragment,
  PurchaseVerificationFragment,
  RegistrationType,
  UserOptionFragment,
} from 'Apollo/graphql';
import { InputProductCountMultipleValueOption, } from 'Components/Inputs/InputProductCountMultiple/types';
import { GenderTitleOption, } from 'Utils/options/useOptionsGenderTitle';
import { PhonePrefixOption, } from 'Utils/options/useOptionsPhonePrefix';
import { UserVerificationOption, } from 'Utils/options/useOptionsUserVerification';
import create from 'zustand';
import {
  mapCategoriesOntoDeviceCodeList,
  reduceAssortmentCategoryToEmptyStoreValues,
  remapDeviceCodeList,
  remapPromotionAssortmentList,
  remapSingleEntry,
  StoreAssortment,
  StorePromotionAssortment,
} from './helpers';

export type RegistrationPhase =
  | 'init'
  | 'purchase'
  | 'customerRegistration'
  | 'customerVerification'
  | 'informationVerification'
  | 'registrationComplete'
  | 'deviceCodes'
  | 'deviceCodesPromotion'
  | 'promotionPurchase'
  | 'chooseVerificationMethod';

export type RegistrationAssortmentValue = InputProductCountMultipleValueOption<AssortmentOptionFragment>;
export type CustomerRegistrationForm = {
  title: GenderTitleOption | null;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | null;
  phone: string;
  phonePrefix: PhonePrefixOption | null;
  purchasedProducts: PurchasedProductsForGqlDbOptionFragment | null;
  previouslyOwnedDevices: PreviouslyOwnedDevicesOptionFragment | null;
  deviceCode: string;
  acceptedTermsAndConditions: boolean;
  acceptedPulzeCare: boolean;
  acceptedPulzeOn: boolean;
  verificationMethod: UserVerificationOption | null;
};
export type ChooseVerificationMethodForm = {
  verificationMethod: UserVerificationOption | null;
  phone: string;
  phonePrefix: PhonePrefixOption | null;
};

export type InformationVerificationFormValues = {
  user: UserOptionFragment | null;
  pos: PointOfSellOptionFragment | null;
  username: string
};
export type RegistrationCustomer = CustomerInput & {id?:string};

export type AssortmentCategories = {
  [key: string]: RegistrationAssortmentValue[];
};

export type PurchasedAssortmentInStore = {
  assortmentId: string;
  count: number;
  option: AssortmentOptionFragment;
  deviceCode?: string;
};

export type RegistrationFormPromotionValue = InputProductCountMultipleValueOption<PromotionAvailabilityFragment>;
export type RegistrationPromotionValue = {
  assortmentCategories: AssortmentCategories;
  promotion: PromotionWithLimitOptionFragment;
  assortmentsWithDeviceCodes: PurchasedAssortmentInStore[];
  assortmentsWithoutDeviceCodes: PurchasedAssortmentInStore[];
  isValid: boolean;
};
export type PromotionCategoryPurchaseObject = {
  [key: string]: RegistrationPromotionValue[];
};
export type ActivePromotionPurchase = {
  id: string | null;
  index: number | null;
};
type RegistrationStoreState = {
  phase: RegistrationPhase;
  registrationEmail: string;
  shouldCreateUser: boolean;
  customer: RegistrationCustomer | null;
  purchase: {
    promotions: RegistrationFormPromotionValue[];
    assortmentCategories: AssortmentCategories;
  };
  deviceCodeRequiredList: StoreAssortment[];
  deviceCodeIndex: number;
  deviceCodeRequiredPromotionsList: StorePromotionAssortment[];
  deviceCodePromotionsIndex: number;
  customerRegistration: CustomerRegistrationForm;
  promotionCategoryPurchaseList: PromotionCategoryPurchaseObject;
  activePromotionPurchase: ActivePromotionPurchase;
  verification: {
    shouldVerify: boolean;
    isVerified: boolean;
    method: CustomerVerificationMethod | null;
    value: string | null;
  };
  informationVerification: InformationVerificationFormValues;
  purchaseVerification: PurchaseVerificationFragment | null;
  isShortRegistration?: boolean
  customerVerificationResult: CustomerVerificationStartResult  | null;
};

export type RegistrationStore = RegistrationStoreState & {
  completeChooseVerificationMethodPhase: (verificationMethod: CustomerVerificationMethod,valueToVerify: string,customerVerificationResult: CustomerVerificationStartResult) => void;
  completeInitPhase: (customer: RegistrationCustomer | null, registrationEmail: string, doi: boolean, onlyRegistration: boolean) => void;
  completePurchasePhase: (args: RegistrationStore['purchase'], phase: RegistrationPhase) => void;
  inputPromotionPurchase: (newEntries: AssortmentCategories, id: string, index: number) => void;
  completePromotionPurchasePhase: () => void;
  completeDeviceCodesPhase: () => void;
  inputDeviceCode: (index: number, deviceCode: string) => void;
  goBackInDeviceCodes: () => void;
  completeDeviceCodesPromotionPhase: () => void;
  inputDeviceCodePromotion: (index: number, deviceCode: string) => void;
  goBackInDeviceCodesPromotion: () => void;
  setActivePromotionPurchase: (index: number, id: string) => void;
  resetIndexes: () => void;
  completeCustomerRegistrationPhase: (customerRegistration: CustomerRegistrationForm, verification: RegistrationStore['verification'],customerVerificationResult?: CustomerVerificationStartResult) => void;
  completeCustomerVerificationPhase: (verification: RegistrationStore['verification']) => void;
  registerCustomer: (customer: RegistrationStore['customer']) => void;
  completeInformationVerificationPhase: (
    purchaseVerification: PurchaseVerificationFragment | undefined,
    informationVerification: InformationVerificationFormValues,
  ) => void;
  setCustomerRegistration: (args: CustomerRegistrationForm) => void;
  setInformationVerification: (informationVerification: InformationVerificationFormValues) => void;
  goBackToPhase: (phase: RegistrationPhase) => void;
  resetStore: (isShortRegistration?: boolean) => void;
  handleChangeInPromotionPurchaseForm: (option: PromotionAvailabilityFragment, toggle: "add" | "remove") => void;
  resetVerification: () => void;
};

export const INIT_STATE: RegistrationStoreState = {
  phase: 'init',
  registrationEmail: '',
  shouldCreateUser: false,
  customer: null,
  purchase: {
    promotions: [],
    assortmentCategories: {},
  },
  deviceCodeRequiredList: [],
  deviceCodeIndex: 0,
  deviceCodeRequiredPromotionsList: [],
  deviceCodePromotionsIndex: 0,
  promotionCategoryPurchaseList: {},
  activePromotionPurchase: { id: null, index: null, },
  customerRegistration: {
    title: null,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    phone: '',
    phonePrefix: null,
    purchasedProducts: null,
    previouslyOwnedDevices: null,
    deviceCode: '',
    acceptedTermsAndConditions: false,
    acceptedPulzeCare: false,
    acceptedPulzeOn: false,
    verificationMethod: null,
  },
  verification: {
    shouldVerify: true,
    isVerified: false,
    method: null,
    value: null,
  },
  informationVerification: {
    pos: null,
    user: null,
    username: '',
  },
  purchaseVerification: null,
  customerVerificationResult: null,
};

export const useRegistrationStore = create<RegistrationStore>((set) => ({
  ...INIT_STATE,
  completeChooseVerificationMethodPhase: (verificationMethod,valueToVerify,customerVerificationResult) => set((state) => ({
    ...state,
    verification: {
      ...state.verification,
      method: verificationMethod,
      value: valueToVerify,
      shouldVerify: true,
      isVerified: false,
    },
    customerVerificationResult,
    phase: 'customerVerification',
  })),
  completeInitPhase: (customer, registrationEmail, doi, onlyRegistration = false) =>
    set((state) => {
      return ({
        ...INIT_STATE,
        phase: onlyRegistration ? 'customerRegistration' : 'purchase',
        registrationEmail,
        shouldCreateUser: !customer,
        customer,
        verification: {
          ...INIT_STATE.verification,
          shouldVerify: !customer || !doi,
        },
      });
    }),
  completePurchasePhase: ({ promotions, assortmentCategories, }, phase) => {
    set((state) => {
      const remappedPromotionCategoryPurchaseList = remapPromotionAssortmentList(state.promotionCategoryPurchaseList, promotions);
      return {
        deviceCodeRequiredList: remapDeviceCodeList(state.deviceCodeRequiredList, assortmentCategories),
        deviceCodeRequiredPromotionsList: mapCategoriesOntoDeviceCodeList(
          remappedPromotionCategoryPurchaseList,
          state.deviceCodeRequiredPromotionsList,
        ),
        promotionCategoryPurchaseList: remappedPromotionCategoryPurchaseList,
        phase,
        purchase: {
          promotions,
          assortmentCategories,
        },
      };
    });
  },
  completePromotionPurchasePhase: () => {
    set((state) => ({
      phase: 'deviceCodes',
    }));
  },
  inputPromotionPurchase: (newEntries, id, index) =>
    set((state) => ({
      promotionCategoryPurchaseList: remapSingleEntry(state.promotionCategoryPurchaseList, newEntries, id, index),
    })),
  handleChangeInPromotionPurchaseForm: (option,toggle) =>  set((state) => ({
    promotionCategoryPurchaseList: {...state.promotionCategoryPurchaseList, [option.promotion.id] : toggle ==="remove" ? state.promotionCategoryPurchaseList[option.promotion.id].slice(0, -1) : [...(state.promotionCategoryPurchaseList[option.promotion.id] || []),{assortmentCategories: reduceAssortmentCategoryToEmptyStoreValues(option.promotion.promotionAssortmentCategoriesForPurchases as unknown as PromotionAssortmentCategoryFragment),assortmentsWithDeviceCodes:[],assortmentsWithoutDeviceCodes:[],promotion:option.promotion,isValid: false,}, ],},
  })),
  setActivePromotionPurchase: (index, id) =>
    set((state) => ({
      activePromotionPurchase: {
        id,
        index,
      },
    })),
  completeDeviceCodesPhase: () => {
    set((state) => ({
      phase: 'deviceCodesPromotion',
      deviceCodeIndex: state.deviceCodeIndex - 1,
    }));
  },
  inputDeviceCode: (index, deviceCode) =>
    set((state) => ({
      deviceCodeRequiredList: state.deviceCodeRequiredList.map((assortment, ind) => {
        if (index === ind) {
          return { ...assortment, deviceCode, };
        }
        return { ...assortment, };
      }),
      deviceCodeIndex: state.deviceCodeIndex + 1,
    })),
  goBackInDeviceCodes: () => {
    return set((state) => ({
      deviceCodeIndex: state.deviceCodeIndex - 1 < 0 ? 0 : state.deviceCodeIndex - 1,
    }));
  },
  completeDeviceCodesPromotionPhase: () => {
    set((state) => {
      const phaseIfChooseMethod = state.verification.shouldVerify ? 'chooseVerificationMethod' : 'informationVerification';
      return ({
      phase: state.shouldCreateUser ? 'customerRegistration' : phaseIfChooseMethod,
      deviceCodePromotionsIndex: state.deviceCodePromotionsIndex - 1,
    });});
  },
  inputDeviceCodePromotion: (index, deviceCode) =>
    set((state) => ({
      deviceCodeRequiredPromotionsList: state.deviceCodeRequiredPromotionsList.map((assortment, ind) => {
        if (index === ind) {
          return {
            ...assortment,
            assortment: {
              ...assortment.assortment,
              deviceCode,
            },
          };
        }
        return { ...assortment, };
      }),
      deviceCodePromotionsIndex: state.deviceCodePromotionsIndex + 1,
    })),

  goBackInDeviceCodesPromotion: () => {
    return set((state) => ({
      deviceCodePromotionsIndex: state.deviceCodePromotionsIndex === 0 ? 0 : state.deviceCodePromotionsIndex - 1,
    }));
  },
  completeCustomerRegistrationPhase: (customerRegistration, verification,customerVerificationResult) =>
    set((state) => {
      return {
        phase: 'customerVerification',
        customer: {
          id: undefined,
          email: state.registrationEmail,
          firstName: customerRegistration.firstName,
          lastName: customerRegistration.lastName,
          phoneNumber: customerRegistration.phone,
          phonePrefix: customerRegistration.phonePrefix?.id || '',
          dateOfBirth: customerRegistration.dateOfBirth ? new Date(customerRegistration.dateOfBirth) : null,
          acceptedPulzeCare: customerRegistration.acceptedPulzeCare,
          acceptedPulzeOn: customerRegistration.acceptedPulzeOn,
          acceptedTermsAndConditions: customerRegistration.acceptedTermsAndConditions,
          previouslyOwnedDevices: customerRegistration.previouslyOwnedDevices?.id,
          purchasedProductsForGlobalDB: customerRegistration.purchasedProducts?.id,
          // FIXME: this should not be there, should be handled by the backend
          registrationType: RegistrationType.Standard
        },
        customerRegistration,
        verification,
        customerVerificationResult,
      };
    }),
  completeCustomerVerificationPhase: (verification) =>
    set((state) => ({
      phase: 'informationVerification',
      verification,
    })),

  completeInformationVerificationPhase: (purchaseVerification, informationVerification) =>
    set({
      phase: 'registrationComplete',
      purchaseVerification,
      informationVerification,
    }),
  setCustomerRegistration: (customerRegistration) =>
    set({
      customerRegistration,
    }),
  setInformationVerification: (informationVerification) =>
    set({
      informationVerification,
    }),
  registerCustomer: (customer) =>
    set((state) => ({
      shouldCreateUser: false,
      customer,
    })),
  resetStore: (isShortRegistration) =>
    set({
      ...INIT_STATE,
      isShortRegistration,
      phase: isShortRegistration ? 'purchase' : 'init',
    }),
  goBackToPhase: (phase) =>
    set((state) => ({
      phase,
    })),
  resetIndexes: ()=> set((state) => ({
    deviceCodeIndex: 0,
    deviceCodePromotionsIndex:0,
  })),
  resetVerification: () =>
    set((state) => ({
      verification: {
        ...INIT_STATE.verification,
        shouldVerify: state.verification.shouldVerify,
      },
    })),
}));
